@import '@scss/color.scss';
@import '@scss/global.scss';
@import '@scss/mixin.scss';

.app-cover {
  @include black-bg(0.65);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 666660;
  display: flex;
  align-items: center;
  justify-content: center;
}
