@import '@scss/color.scss';
@import '@scss/global.scss';
@import '@scss/mixin.scss';

.login-cover {
  @include black-bg(0.65);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 6600;
  display: flex;
  align-items: center;
  justify-content: center;

  .login-0101 {
    @include white-bg(1);
    position: relative;
    display: flex;
    flex-direction: column;
    width: 520px;
    height: 200px;
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 20%);

    .title-101 {
      display: inline-flex;
      margin-top: 20px;
      padding: 0 20px;

      span {
        font-size: 16px;
        padding-top: 6px;
        font-weight: 600;
        line-height: 1;
        border-top: 2px solid $secondary;
        color: $c333;
      }
    }
    .text-101 {
      flex: 1;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      color: $c333;
    }
    .bottom-101 {
      display: inline-flex;
      justify-content: flex-end;
      align-items: center;
      height: 52px;
      width: 100%;
      padding: 0 20px;
      border-top: 1px solid rgba(0, 0, 0, 0.08);
    }
    .close-101 {
      position: absolute;
      right: -40px;
      top: 0;
      width: 36px;
      height: 36px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }
}
