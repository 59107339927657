@import '@scss/color.scss';
@import '@scss/global.scss';
@import '@scss/mixin.scss';

.app-login {
  display: flex;
  align-items: center;
  justify-content: center;

  .section-1505 {
    @include white-bg(1);
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 10px 20px 20px 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.08);
  }
}
