@import "@scss/color.scss";
@import "@scss/global.scss";
@import "@scss/mixin.scss";

.function-sounce-picture {
  display: flex;
  justify-content: flex-start;
  .function-sounce-picture-left {
    width: 35%;
    padding: 0 0 0 20px;
    margin: 20px 0 20px 0;
    position: relative;
    .inflow-sounce-picture-left-picture {
      position: absolute;
      right: 0;
      top: 31px;
      width: calc(100% - 118px);
    }
    .left-items {
      cursor: pointer;
    }
    .left-items-active {
      background-color: $primary;
    }
  }
  .function-sounce-picture-mid {
    width: 65%;
    padding: 40px 30px;
    position: relative;
    .out {
      span {
        background: $secondary;
      }
    }
    .in {
      span {
        background: $primary;
      }
    }
    .mid-items {
      margin-top: 0;
      padding: 4px 20px;
      p {
        overflow: hidden;
        padding-bottom: 10px;
        b {
          display: block;
          float: left;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          border: 1px solid $ccc;
          margin: 8px 20px 0 0;
        }
        span {
          @include mont-font;
          float: right;
          font-size: 16px;
          color: $ccc;
        }
        .mid-item-name {
          color: $ccc;
          float: left;
        }
      }
    }
    .mid-items1 {
      @include primary-bg(0.04);
      margin-top: 0;
      padding: 4px 20px;
      p {
        overflow: hidden;
        padding-bottom: 10px;
        b {
          display: block;
          float: left;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          border: 1px solid $ccc;
          margin: 8px 20px 0 0;
          background-color: $primary;
        }
        span {
          @include mont-font;
          float: right;
          font-size: 16px;
          color: $black;
        }
        .mid-item-name {
          color: $black;
          float: left;
        }
      }
    }
    .mid-item-connect {
      position: absolute;
      right: -35px;
      top: 27px;
      width: 80px;
      display: flex;
      justify-content: flex-start;
      span {
        display: inline-block;
        width: 10px;
        height: 10px;
        background-color: $primary;
        border-radius: 50%;
      }
      b {
        display: inline-block;
        width: 70px;
        height: 2px;
        background-color: $primary;
        margin-left: -5px;
        margin-top: 4px;
      }
    }
    .mid-item-left-connect {
      width: 54px;
      height: 1px;
      background: $primary;
      position: absolute;
      left: -1px;
      top: 71px;
    }
  }
  .function-sounce-picture-right {
    padding: 0 30px;
    display: flex;
    justify-content: flex-start;
    width: 40%;
    .left-detail {
      width: 50px;
      border-radius: 10px 0 0 10px;
      border: 2px solid $primary;
      border-right: 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .right-detail {
      width: 100%;
      .right-items {
        padding: 4px 20px;
        margin-top: 10px;
        p {
          overflow: hidden;
          b {
            display: block;
            float: left;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            border: 1px solid $black;
            margin: 8px 20px 0 0;
          }
          span {
            @include mont-font;
            float: right;
            font-size: 16px;
            color: $black;
          }
          .right-item-name {
            color: $black;
            float: left;
          }
        }
      }
    }
  }
}
