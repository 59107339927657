@import "@scss/color.scss";
@import "@scss/global.scss";
@import "@scss/mixin.scss";

.city-choose_10 {
  display: inline-flex;
  align-items: center;

  b {
    @include black-color(0.65);
    margin-right: 10px;
    font-size: 14px;
    font-weight: 500;
    line-height: 1;
  }
  .line-11 {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    height: 30px;
    min-width: 150px;
    padding: 0 10px;
    border-radius: 4px;
    cursor: pointer;
    border: 1px solid rgba(0, 0, 0, 0.1);

    span {
      display: inline-flex;
      align-items: center;
      height: 20px;
      padding: 0 6px;
      margin-right: 10px;
      font-size: 14px;
      font-weight: 400;
      line-height: 1;
      border-radius: 5px;
      color: $fff;
      background-color: $primary;
    }
  }
}
