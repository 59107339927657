@import "@scss/color.scss";
@import "@scss/global.scss";
@import "@scss/mixin.scss";

.table-60655 {
  @include white-bg(1);
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  padding: 20px;
  border-radius: 5px;

  .content-101 {
    display: inline-flex;
  }
  .content-103 {
    display: inline-flex;
    flex-direction: column;
    max-height: 360px;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .row-101 {
    @include primary-bg(0.1);
    display: inline-flex;
    flex-direction: column;

    &:first-child, &:nth-child(2), &:nth-child(3) {
      @include primary-bg(0.1);
      width: 100px;
    }
    &:first-child, &:nth-child(2), &:nth-child(3), &:nth-child(4) {
      border-right: 1px solid $fff;
    }
    &:nth-child(4) { flex: 5; }
    &:nth-child(5) { flex: 4; }
    strong {
      @include black-color(1);
      flex: 1;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      // width: 100%;
      min-height: 36px;
      font-size: 14px;
      font-weight: 500;
      line-height: 1;
    }
    .line-101 {
      display: inline-flex;

      b {
        flex: 1;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        min-width: 75px;
        height: 36px;
        line-height: 1;
        font-size: 12px;
        font-weight: 400;
        border-top: 1px solid $fff;
        color: $c55;

        &:nth-child(2), &:nth-child(3),
        &:nth-child(4), &:nth-child(5) {
          border-left: 1px solid $fff;
        }
      }
    }
  }
  .row-103 {
    display: inline-flex;

    .span-11 {
      @include mont-font();
      width: 120px;
      height: 36px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      line-height: 1;
      font-size: 14px;
      font-weight: 600;
    }
    .line-11 {
      display: inline-flex;
      flex: 1;
    }
    .span-13 {
      @include mont-font();
      min-width: 75px;
      flex: 1;
      height: 36px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      line-height: 1;
      font-size: 14px;
      font-weight: 400;
      // border-right: 1px solid $secondary;
    }
    &:nth-child(even) {
      span { background-color: $f7; }
      // .span-0013 { background-color: #EAEBF4; }
      .span-0013 { @include primary-bg(0.1); }
    }
    .span-0013 {
      @include pingfang-font();
      width: 100px;
      min-width: 100px;
      max-width: 100px;
      border-right: 1px solid $fff;
      background-color: #EAEBF4;
    }
  }
}
