@import '@scss/color.scss';
@import '@scss/global.scss';
@import '@scss/mixin.scss';

.app-consult_555 {
  position: fixed;
  right: 10px;
  top: 40%;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  z-index: 999999999;

  .img-110 { cursor: pointer; }
  .img-111 { margin-bottom: -5px; }
  .img-112, .img-113 { margin: -5px 0; }
  .img-115 { margin-top: -5px; }

  .phone-50 {
    @include white-bg(1);
    position: absolute;
    top: 10px;
    right: 60px;
    width: 160px;
    height: 40px;
    display: inline-flex;
    align-items: center;
    box-shadow: 0 0 10px 0 rgba(28, 59, 93, 0.15);
    border-radius: 20px;
    border-image: linear-gradient(180deg, rgba(255, 255, 255, 1), rgba(243, 245, 248, 1)) 1 1;

    .img-115 {
      position: absolute;
      top: -5px;
      left: -10px;
    }
    p {
      display: inline-flex;
      flex-direction: column;
      margin-left: 45px;

      span {
        @include mont-font();
        @include black-color(1);
        font-size: 14px;
        font-weight: 600;
        line-height: 1.1;
      }
      i {
        font-size: 10px;
        font-weight: 500;
        color: $c87;
      }
    }
  }
  .qrcode-50 {
    @include white-bg(1);
    position: absolute;
    bottom: -40px;
    right: 60px;
    width: 160px;
    height: 160px;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    box-shadow: 0 0 10px 0 rgba(28, 59, 93, 0.15);
    border-image: linear-gradient(180deg, rgba(255, 255, 255, 1), rgba(243, 245, 248, 1)) 1 1;

    span {
      margin-bottom: 2px;
      font-size: 10px;
      font-weight: 500;
      color: $c87;
    }
    img {
      width: 130px;
      height: 130px;
    }
  }
}
