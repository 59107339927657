@import "@scss/color.scss";
@import "@scss/global.scss";
@import "@scss/mixin.scss";

.talent-portrait-warp {
  .tops-title-warp {
    display: flex;
    justify-content: space-between;
    .wealfare-title-111 {
      width: 100%;
    }
  }
  .portrait-tab {
    margin: 20px 0;
  }
  .portrait-warp {
    background-color: $f7;
    padding: 20px 10px 10px;
    margin-top: 20px;
    border-radius: 8px;
    .svg-group {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .portrait-talent-warp {
        margin-top: 20px;
        border-radius: 8px;
        background-color: $fff;
        padding: 20px 10px;
        width: calc(50% - 5px);
        &:nth-child(1) {
          margin-top: 0;
        }
        &:nth-child(2) {
          margin-top: 0;
        }
        .two-sub-title {
          font-size: 16px;
          color: $black;
          text-align: center;
          font-weight: 600;
        }
        .portrait-talent-scale {
          flex: 1;
          display: inline-flex;
          flex-direction: column;

          .value {
            @include mont-font;
          }
          .one {
            span {
              background-color: $t7One;
            }
          }
          .two {
            span {
              background-color: $t7Two;
            }
          }
          .three {
            span {
              background-color: $t7Three;
            }
          }
          .four {
            span {
              background-color: $t7Four;
            }
          }
          .five {
            span {
              background-color: $t7Five;
            }
          }
          .six {
            span {
              background-color: $t7Six;
            }
          }
          .seven {
            span {
              background-color: $t7Seven;
            }
          }
          .success {
            span {
              background-color: $t7Seven;
            }
          }

          .progres-hover-item,
          .progress_0911 {
            display: inline-flex;
            align-items: center;
            justify-content: space-between;
            height: 42px;
            margin-left: 20px;
            cursor: pointer;
            padding: 8px 10px;
            border-radius: 4px;

            & > p {
              flex: 1;
              display: inline-flex;
              flex-direction: column;
              justify-content: space-between;
              height: 100%;

              span {
                width: 100%;
                line-height: 1;
              }
            }
            .value {
              @include black-color(1);
              font-size: 16px;
              font-weight: 600;
              margin-left: 5px;
              display: block;
              width: 45px;
            }
          }
          .progres-hover-item {
            @include primary-bg(0.04);
          }
          .progress_0911:hover {
            @include primary-bg(0.04);
          }
        }
        .portrait-talent-item {
          display: flex;
          margin-top: 20px;
        }
      }
    }
  }
  .sex-distribution-content {
    margin-top: 20px;
    border-radius: 8px;
    background-color: $fff;
    padding: 20px 10px;
    width: calc(50% - 5px);
    .sub-title {
      font-size: 16px;
      color: $black;
      text-align: center;
      font-weight: 600;
    }
    .sex-distribution-chart {
      margin-top: 25px;
      display: flex;
      justify-content: space-between;
      .sex-svg {
        width: 190px;
        .sex-icon-item {
          margin-left: 5px;
        }
      }
      .sex-progress {
        width: 50%;
        .progress-item {
          .one {
            span {
              background-color: $t7One;
            }
          }
          .two {
            span {
              background-color: $t7Two;
            }
          }
          .three {
            span {
              background-color: $t7Three;
            }
          }
          .four {
            span {
              background-color: $t7Four;
            }
          }
          .five {
            span {
              background-color: $t7Five;
            }
          }
          .six {
            span {
              background-color: $t7Six;
            }
          }
          .seven {
            span {
              background-color: $t7Seven;
            }
          }
        }
        .progress-item {
          display: inline-flex;
          align-items: center;
          height: 42px;
          margin-left: 20px;
          cursor: pointer;
          & > p {
            display: inline-flex;
            flex-direction: column;
            height: 100%;
          }
          .value {
            @include black-color(1);
            font-size: 16px;
            font-weight: 600;
          }
        }
      }
    }
  }
  .top-rank10-warp {
    margin-top: 20px;
    border-radius: 8px;
    background-color: $fff;
    padding: 20px 10px;
    .rank10-title {
      font-size: 16px;
      color: $black;
      text-align: center;
      font-weight: 600;
    }
    .top-rank-svg {
      width: 100%;
      height: 450px;
    }
  }
}
