@import "@scss/color.scss";
@import "@scss/global.scss";
@import "@scss/mixin.scss";

.common-tab-warp {
  height: 36px;
  padding: 2px;
  justify-content: center;
  border-radius: 4px;
  background-color: $white;
  border-radius: 5px;
  display: inline-flex;
  .tab-item {
    width: 80px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    margin: 0 3px;
    cursor: pointer;
    background-color: $fff;
    border-radius: 4px;
    font-size: 12px;
  }

  .tab-item-active {
    font-size: 12px;
    width: 80px;
    height: 30px;
    background-color: $primary;
    color: $fff;
    text-align: center;
    line-height: 30px;
    cursor: pointer;
    border-radius: 4px;
    margin: 0 3px;
  }
}
