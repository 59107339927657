@import "@scss/color.scss";
@import "@scss/global.scss";
@import "@scss/mixin.scss";

.wealfare-insight-pdf-warps {
  .budget-pdf-warp {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 100%;
  }
  .budget-top-title {
    flex: 1;
    padding-top: 26px;
    .welfare-budget {
      margin-top: 10px;
      width: 100%;
      background-color: $fff;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      .budget-svg {
        border-radius: 8px;
        background-color: $fff;
        margin-top: 20px;
        height: 215px;
        width: 100%;
      }
    }
  }
  .holiday-pdf-warp {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 100%;
  }
  .holiday-top-title {
    flex: 1;
    padding-top: 26px;
    .holiday-svg-warp {
      margin-top: 10px;
      width: 515px;
      height: 370px;
      background: $fff;
      border-radius: 5px;
      .holiday-svg {
        height: 300px;
        width: 100%;
        overflow-x: auto;
      }
    }
    .holiday-table-warp {
      margin-top: 20px;
      .table-title {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid $ccc;
        margin-bottom: 10px;
        padding-bottom: 1px;
        p {
          width: 27.2%;
          color: $fff;
          height: 21px;
          border-radius: 4px;
          text-align: center;
          text-align: center;
          font-size: 12px;
          font-weight: 500;
          line-height: 21px;
          &:nth-child(1) {
            width: 15.9%;
          }
        }
      }
      .table-content {
        p {
          display: flex;
          justify-content: space-between;
          span {
            @include mont-font;
            display: block;
            width: 27.2%;
            text-align: center;
            font-size: 12px;
            color: #181c25;
            font-weight: 600;
            height: 21px;
            line-height: 21px;
            margin-bottom: 1px;
            background-color: $white;
            &:nth-child(1) {
              color: $white;
              background-color: $c999;
              width: 15.9%;
            }
          }
          &:nth-child(1) {
            span {
              border-radius: 4px 4px 0 0;
            }
          }
          &:last-child {
            span {
              border-radius: 0 0 4px 4px;
            }
          }
        }
      }
    }
  }
}
