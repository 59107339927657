@import "@scss/color.scss";
@import "@scss/global.scss";
@import "@scss/mixin.scss";

.industry-report_10 {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  padding: 10px;
  border-radius: 8px;
  margin-bottom: 60px;
  background-color: $f7;

  .line-301 {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
    padding-right: 10px;
  }
  strong.sub-11 {
    @include black-color(0.85);
    margin-left: 6px;
    font-size: 16px;
    font-weight: 600;
    line-height: 1;
  }

  .slick-section {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    padding-bottom: 20px;

    .card-030 {
      @include white-bg(1);
      flex: 1;
      position: relative;
      bottom: -4px;
      display: flex;
      flex-direction: column;
      height: 200px;
      width: 264px;
      margin: 0 4px;
      border-radius: 8px;
      cursor: pointer;

      .section-361 {
        flex: 1;
        display: inline-flex;
        flex-direction: column;
        z-index: 55;
      }

      img.img-007 {
        // width: 100%;
        height: 109px;
        border-top-left-radius: 8px;
        // border-top-right-radius: 8px;
      }

      p.card-line-29 {
        display: inline-flex;
        width: 100%;
        height: 110px;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        // background-color: $f1;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;

        &.primary-bg { @include primary-bg(1); }
      }
      p.card-line-30 {
        flex: 1;
        display: inline-flex;
        padding: 12px 16px;

        span {
          @include black-color(0.5);
          font-size: 12px;
          flex: 1;
          display: inline-flex;
          flex-direction: column;
          justify-content: space-between;
        }
      }

      &.active {
        bottom: 0;
        box-shadow: 0 0 10px 0 rgba(52, 63, 153, 0.2);
      }
    }

    .slick-0505 {
      display: flex;
      width: 264px;
      height: 200px;
    }
  }
  .blur_006 {
    width: 100%;
    height: 32px;
    position: absolute;
    bottom: -24px;
    z-index: 0;
  }
}
