@import "@scss/color.scss";
@import "@scss/global.scss";
@import "@scss/mixin.scss";

.industry-chain_10 {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  padding: 10px;
  border-radius: 8px;
  background-color: $f7;

  strong.sub-11 {
    @include black-color(0.85);
    margin-top: 10px;
    margin-left: 6px;
    font-size: 16px;
    font-weight: 600;
    line-height: 1;
  }

  .chain-10 {
    @include white-bg(1);
    display: flex;
    flex-direction: column;
    padding: 10px 25px 20px 25px;
    margin-top: 30px;
    border-radius: 5px;

    .chain-head_10 {
      display: flex;

      .one-1 {
        display:inline-flex;
        width: 70px;
        margin-right: 48px;
      }
      .one-2 {
        display: inline-flex;
        width: 180px;
      }
      .one-3 {
        flex: 1;
        display: inline-flex;
        margin-left: 10px;
      }
      .one-1, .one-2, .one-3 {
        @include black-color(0.5);
        font-size: 12px;
        font-weight: 400;
        line-height: 1;
        padding: 10px 12px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.08);
      }
    }
    .chain-content_10 {
      display: flex;
      flex-direction: column;

      .chain-11 {
        display: flex;
        flex-direction: column;
      }
      .content-155 {
        display: flex;
        // margin-top: 16px;

        .section-109 {
          flex: 1;
          display: flex;
          flex-direction: column;

          .section-111 {
            display: flex;
            margin-top: 16px;
          }
        }
        .type-105 {
          display: inline-flex;
          align-items: center;
          justify-content: center;
        }
        b {
          @include black-color(0.5);
          display: inline-flex;
          align-items: center;
          justify-content: center;
          width: 70px;
          height: 46px;
          margin-right: 48px;
          border-radius: 4px;
          font-size: 14px;
          border: 1px solid rgba(0, 0, 0, 0.08);

          &.hover {
            @include black-color(0.85);
            @include primary-bg(0.1);
            border: 1px solid rgba(52, 63, 153, 0.2);
          }
          &.active {
            @include black-color(0.85);
            @include primary-bg(0.1);
            border: 1px solid $primary;
          }
        }
        strong {
          @include primary-bg(0.04);
          @include black-color(0.85);
          display: inline-flex;
          align-items: center;
          justify-content: center;
          width: 180px;
          min-height: 68px;
          border-radius: 4px;
          font-weight: 500;
          border: 1px solid rgba(52, 63, 153, 0.08);

          &.hover {
            @include primary-bg(0.1);
            border: 1px solid rgba(52, 63, 153, 0.2);
          }
          &.active {
            @include primary-bg(1);
            @include white-color(1);
            border: 1px solid rgba(52, 63, 153, 1);
          }
        }
        .com-11 {
          flex: 1;
          display: inline-flex;
          flex-direction: column;
          justify-content: space-between;
          margin-left: 10px;
          padding: 12px 20px 6px 20px;
          border-radius: 4px;
          border: 1px solid rgba(52, 63, 153, 0.08);

          span {
            @include black-color(0.85);
            font-size: 14px;
          }
          &.active, &.hover {
            @include primary-bg(0.1);
            border: 1px solid rgba(52, 63, 153, 0.2);
          }
        }
        .line-165 {
          display: inline-flex;
          flex-wrap: wrap;
        }
        .line-166 {
          display: inline-flex;
          align-items: center;
          justify-content: flex-end;

          .line-167 {
            display: inline-flex;
            align-items: center;

            .text-190 {
              @include black-color(0.5);
              margin: 0 4px;
              font-size: 12px;
              line-height: 1;
            }
            .active-190 { @include primary-color(1); }
            .text-191 {
              @include mont-font();
              @include black-color(0.85);
              position: relative;
              font-weight: 600;
              font-size: 12px;
              line-height: 1;
              top: 1px;
              margin-left: 3px;
            }
          }
          .line-169 { margin-left: 10px; }
        }
      }
    }
  }
}
