@import "@scss/color.scss";
@import "@scss/global.scss";
@import "@scss/mixin.scss";

.scale-0102 {
  @include white-bg(1);
  width: 100%;
  height: 360px;
  padding: 20px;
  border-radius: 8px;

  .scale_0911 {
    flex: 1;
    display: inline-flex;
    flex-direction: column;
    // margin-top: 25px;

    .value {
      @include mont-font();
    }
    .one { span { background-color: $t7One; } }
    .two { span { background-color: $t7Two; } }
    .three { span { background-color: $t7Three; } }
    .four { span { background-color: $t7Four; } }
    .five { span { background-color: $t7Five; } }
    .six { span { background-color: $t7Six; } }
    .seven { span { background-color: $t7Seven; } }

    .progres-hover-item, .progress_0911 {
      display: inline-flex;
      align-items: center;
      justify-content: space-between;
      height: 42px;
      margin-left: 20px;
      cursor: pointer;
      padding: 8px 10px;
      border-radius: 4px;

      & > p {
        flex: 1;
        display: inline-flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;

        span {
          width: 100%;
          line-height: 1;
        }
      }
      .value {
        @include black-color(1);
        font-size: 15px;
        font-weight: 600;
        margin-left: 5px;
        display: block;
        width: 45px;
      }
    }
    .progres-hover-item { @include primary-bg(0.04); }
    .progress_0911:hover { @include primary-bg(0.04); }
  }
  .sub-title {
    @include black-color(0.85);
    font-size: 16px;
    font-weight: 600;
    line-height: 1;
    padding-top: 4px;
    border-top: 2px solid $secondary;
    display: inline-block;
  }
  .portrait-item-003 {
    display: flex;
    align-items: center;
    height: 320px;
  }
}
