@import '@scss/color.scss';
@import '@scss/global.scss';
@import '@scss/mixin.scss';

.app-alert {
  position: fixed;
  top: 66px;
  left: 0;
  z-index: 7500;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .text-500 {
    @include black-bg(1);
    @include white-color(1);
    display: inline-flex;
    justify-content: center;
    min-width: 160px;
    padding: 10px;
    border-radius: 6px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  }
}
