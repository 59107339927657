@import "@scss/color.scss";
@import "@scss/global.scss";
@import "@scss/mixin.scss";

.company-distribution_10 {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  padding: 10px;
  border-radius: 8px;
  background-color: $f7;

  strong.sub-11 {
    @include black-color(0.85);
    margin-left: 6px;
    margin-top: 10px;
    font-size: 16px;
    font-weight: 600;
    line-height: 1;
  }
  .section-166 {
    display: flex;
    margin-top: 30px;
    justify-content: space-between;
    .company-chart-distruibution {
      width: calc(100% - 310px);
      height: 520px;
      border-radius: 8px;
      background-color: $fff;
    }
    .company-ranks-warps {
      @include primary-bg(1);
      width: 300px;
      height: 520px;
      padding: 20px 10px;
      overflow: hidden;
      border-radius: 8px;
      .map-top-ranking-content-005 {
        display: flex;
        justify-content: space-between;
        color: $white;
        padding: 7px 5px 6px;
        cursor: pointer;
        .map-item-008 {
          @include white-color(0.85);
          display: block;
          white-space: nowrap;
        }
        .up-item-009 {
          @include mont-font;
          width: 60px;
          display: block;
          text-align: right;
        }
        .up-item-002 {
          @include mont-font;
          width: 60px;
          display: block;
        }
        .svg-xinzhi-logo {
          margin-right: 5px;
        }
        .map-top-progress-001 {
          width: 80px;
          height: 8px;
          padding: 6px 0;
          .MuiLinearProgress-root {
            height: 8px;
            border-radius: 4px;
            span {
              &:nth-child(2) {
                @include white-bg(1);
                height: 8px;
                width: 100px;
                border-radius: 4px;
              }
              &:nth-child(1) {
                margin-top: 3px;
                display: none;
              }
            }
          }
        }
        // &:hover {
        //   @include white-bg(1);
        //   border-radius: 4px;
        //   span {
        //     color: $black;
        //   }
        //   .css-20vwwh-MuiLinearProgress-bar1 {
        //     background: $primary;
        //   }
        // }
      }
      .map-top-ranking-content-008 {
        @include white-bg(1);
        border-radius: 4px;
        display: flex;
        justify-content: space-between;
        color: $white;
        padding: 7px 5px 6px;
        cursor: pointer;
        .map-item-008 {
          color: $black;
          white-space: nowrap;
        }
        .svg-xinzhi-logo {
          margin-right: 5px;
        }
        .up-item-009 {
          @include mont-font;
          width: 60px;
          display: block;
          text-align: right;
        }
        .up-item-002 {
          @include mont-font;
          width: 60px;
          display: block;
        }
        .map-top-progress-001 {
          width: 80px;
          height: 8px;
          padding: 6px 0;
          .MuiLinearProgress-root {
            height: 8px;
            border-radius: 4px;
            span {
              &:nth-child(2) {
                background: $primary;
                height: 8px;
                width: 100px;
                border-radius: 4px;
              }
              &:nth-child(1) {
                margin-top: 3px;
                display: none;
              }
            }
          }
        }
        .map-item-008 {
          color: $black;
          white-space: nowrap;
        }
        span {
          color: $black;
        }
      }
      .sub-title,
      .sub-title01 {
        color: $white;
        font-size: 16px;
        font-weight: 600;
        line-height: 1;
        padding-top: 4px;
        display: inline;
        border-top: 2px solid $secondary;
        margin-left: 5px;
      }
      > p {
        @include white-color(0.5);
        font-size: 12px;
        margin-top: 5px;
        margin-bottom: 10px;
        padding: 0 5px;
      }
    }
  }
}
