@import "@scss/color.scss";
@import "@scss/global.scss";
@import "@scss/mixin.scss";

.common-head {
  .top {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-bottom: 5px;
    .title {
      font-size: 16px;
      color: $black;
      font-weight: 600;
    }
    .subtitle {
      padding: 2px 5px;
      margin: 0 10px;
      font-size: 12px;
      border-radius: 2px;
    }
    .icon-group {
      padding: 1px;
      background-color: $fff;
      border-radius: 3px;
    }
  }
  .com-details {
    color: $c87;
    font-size: 12px;
    font-weight: 400;
    margin-top: 5px;
    margin-bottom: 10px;
  }
  .content {
    p {
      color: $c87;
      font-size: 12px;
      font-weight: 400;
      margin-top: 5px;
    }
  }
}
