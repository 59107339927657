@import "@scss/color.scss";
@import "@scss/global.scss";
@import "@scss/mixin.scss";

.detail-category-rate_10 {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  padding: 20px 10px 10px 10px;
  border-radius: 10px;
  background-color: $f7;

  .one-11 {
    display: inline-flex;

    strong {
      margin-left: 5px;
      font-size: 16px;
      font-weight: 600;
      line-height: 1;
    }
  }
  .one-1300 {
    margin-top: 15px;
    margin-left: 5px;
    font-size: 12px;
    line-height: 1;
    color: $c87;
  }
  .one-13 {
    margin-top: 15px;
    margin-left: 5px;
    font-size: 12px;
    line-height: 1;
  }
  .one-166 {
    display: inline-flex;
    flex-direction: column;

    span {
      line-height: 1.5;
      color: #878787;
      margin-top: 5px;
    }
  }
  .box-159 {
    display: flex;
    overflow-x: auto;
  }
}
