@import "@scss/color.scss";
@import "@scss/global.scss";
@import "@scss/mixin.scss";

.mobility-overview-warp {
  .sub-titles {
    color: $c87;
    font-size: 12px;
    margin-top: 8px;
  }
  .tops-title-warp {
    display: flex;
    justify-content: space-between;
    .wealfare-title-111 {
      width: 100%;
    }
  }
  .mobility-tab {
    margin: 20px 0;
  }
  .mobility-warp {
    background-color: $f7;
    padding: 20px 10px 10px;
    margin-top: 20px;
    border-radius: 8px;
    .talent-warp {
      border-radius: 8px;
      background-color: $fff;
      padding: 20px 10px;
      width: 100%;
      .tab {
        display: flex;
        justify-content: space-between;
        background-color: $f7;
        padding: 10px;
        align-items: center;
        border-radius: 8px;
      }
    }
    .role-group-warp {
      display: flex;
      justify-content: space-between;
      .role-warp {
        width: calc(50% - 5px);
        margin-top: 20px;
        border-radius: 8px;
        background-color: $fff;
        padding: 20px 10px;
        .tab {
          display: flex;
          justify-content: space-between;
          align-items: center;
          background-color: $f7;
          padding: 10px;
          border-radius: 8px;
        }
      }
    }
  }
}
