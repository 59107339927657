@import "@scss/color.scss";
@import "@scss/global.scss";
@import "@scss/mixin.scss";

.company-scale_10 {
  display: flex;
  margin-top: 30px;

  .section-166 {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 10px;
    border-radius: 8px;
    background-color: $f7;

    &:last-child { margin-left: 20px; }
    strong.sub-11 {
      @include black-color(0.85);
      margin-left: 6px;
      margin-top: 10px;
      font-size: 16px;
      font-weight: 600;
      line-height: 1;
    }
    .section-167 {
      display: flex;
      margin-top: 20px;
    }
  }
}
