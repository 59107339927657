@import "@scss/color.scss";
@import "@scss/global.scss";
@import "@scss/mixin.scss";

.index-top_10 {
  display: flex;
  height: 225px;
  padding-top: 30px;
  color: $fff;
  position: relative;
  .img-10306 {
    width: 300px;
    height: 148px;
    cursor: pointer;
    position: absolute;
    right: 40px;
    top: 30px;
    z-index: 99;
    .img-1122 {
      width: 100%;
      height: 100%;
    }
    svg {
      position: absolute;
      bottom: 20px;
      left: 20px;
    }
  }

  .section-10 {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding-right: 30px;

    .section-11 {
      display: inline-flex;
      align-items: flex-end;

      strong {
        font-size: 24px;
        font-weight: 600;
        line-height: 1;
      }

      .line-10 {
        display: inline-flex;
        align-items: center;

        span {
          @include white-color(0.5);
          font-size: 12px;
          font-weight: 400;
          line-height: 1;
        }
      }
      .line-11 { margin-left: 20px; }
      .line-12 { margin-left: 40px; }
    }
    .section-12 {
      @include white-color(0.5);
      font-size: 12px;
      font-weight: 500;
      line-height: 1;
      margin-top: 20px;
    }
    .hline-11 {
      @include white-bg(0.1);
      height: 1px;
      width: 100%;
      margin-top: 36px;
    }
    .section-13 {
      display: inline-flex;
      align-items: center;
      margin-top: 40px;

      .line-13 {
        position: relative;
        display: inline-flex;
        align-items: center;
        justify-content: space-between;
        width: 290px;
        height: 40px;
        padding: 0 10px;
        margin-right: 25px;
        border-radius: 10px;
        background-color: rgba(51, 53, 61, 1);

        p {
          display: inline-flex;
          align-items: center;
        }
        .text-1010 {
          @include white-color(0.5);
          margin-left: 10px;
          font-size: 12px;
          line-height: 1;
        }
        .arrow-1010 {
          display: inline-flex;
          align-items: center;
          margin-left: 5px;
        }
        strong {
          @include mont-font();
          position: relative;
          bottom: 2px;
          font-weight: 600;
          font-size: 20px;
          line-height: 1;
        }
        b {
          @include mont-font();
          margin-left: 10px;
          font-size: 13px;
          line-height: 1;
          font-weight: 600;
        }
        b.up-1011 { color: $error; }
        b.down-1011 { color: $success; }
      }
    }
  }

  .section-160 {
    position: relative;
    display: inline-flex;
    width: 330px;
    height: 185px;

    img {
      width: 100%;
      height: 100%;
      border-radius: 10px;
    }
  }
  .line-popper {
    position: absolute;
    top: -50px;
    width: 100%;
    display: flex;
    justify-content: center;

    p {
      @include white-color(1);
      padding: 0 16px;
      height: 38px;
      font-size: 12px;
      margin-bottom: 12px;
      display: inline-flex;
      align-items: center;
      line-height: 1.3;
      border-radius: 4px;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
      background-color: $dark;
    }
    i {
      position: absolute;
      bottom: 8px;
      transform: rotate(45deg);
      display: inline-flex;
      width: 8px;
      height: 8px;
      background-color: $dark;
    }
  }
  .shadow_0961 {
    position: absolute;
    bottom: -24px;
    left: 0;
    width: 100%;
    height: 32px;
    z-index: 9;

    img {
      width: 100%;
      height: 100%;
    }
  }
}
